.wrap {
  background: var(--theme-background-secondary);
  border-radius: 8px;
  padding: 24px;
  margin: 32px 0;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

/*.header.withMeta {*/
/*  margin-bottom: 12px;*/
/*}*/

.hgroup {
  margin-right: auto;
}

.title {
  display: flex;
  align-items: center;
  margin: 0;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--theme-text-secondary);
  margin: 4px 0 0;
}

.detailsButton {
  margin-left: 4px;
  width: 24px;
  height: 24px;
  display: inline-flex;
  background: transparent;
  padding: 0;
  border: 0;
  color: rgba(var(--theme-text-secondary-rgb), 0.4);
}

.detailsButton:focus,
.detailsButton:hover {
  color: var(--theme-text-primary);
}

.exportButton {
  margin-left: 24px;
}

/*.meta {*/
/*  display: flex;*/
/*  margin-bottom: 32px;*/
/*}*/

/*.metaItem {*/
/*  display: inline-flex;*/
/*  flex-direction: column;*/
/*  border-radius: 8px;*/
/*  background: var(--theme-background-primary);*/
/*  padding: 16px;*/
/*  margin-right: 24px;*/
/*}*/

/*.metaItem:last-child {*/
/*  margin-right: 0;*/
/*}*/

/*.metaTitle {*/
/*  font-weight: 600;*/
/*  font-size: 12px;*/
/*  line-height: 16px;*/
/*  color: var(--theme-text-secondary);*/
/*}*/

/*.metaValue {*/
/*  font-weight: 700;*/
/*  font-size: 18px;*/
/*  line-height: 24px;*/
/*  color: var(--theme-text-primary);*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

/*.metaUnit {*/
/*  font-weight: 600;*/
/*  font-size: 12px;*/
/*  line-height: 16px;*/
/*  margin-left: 4px;*/
/*  color: var(--theme-text-secondary);*/
/*  margin-top: 4px;*/
/*}*/

/*.metaPercent {*/
/*  font-weight: 600;*/
/*  font-size: 12px;*/
/*  line-height: 16px;*/
/*  background: rgba(var(--theme-color-primary-rgb), 0.08);*/
/*  border-radius: 8px;*/
/*  padding: 4px 12px;*/
/*  color: var(--theme-color-primary);*/
/*  margin-left: 16px;*/
/*}*/

.wrap :global(.recharts-cartesian-grid-horizontal) line:first-child {
  opacity: 0;
}

.wrap :global(.recharts-cartesian-axis-tick-value) tspan {
  font-family: inherit;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: 500;
}

.legend {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  position: relative;
}

.legendItem {
  --color: var(--theme-text-primary);

  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  background: var(--theme-background-primary);
  padding: 8px 12px 8px 8px;
  border-radius: 8px;
  color: var(--theme-text-secondary);
  margin-right: 24px;
}

.legendItem:last-child {
  margin-right: 0;
}

.legendItem::before {
  content: '';
  display: block;
  width: 12px;
  height: 4px;
  background: var(--color);
  border-radius: 100px;
  margin-right: 8px;
}

.legendLink {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.tooltip {
  /*transform: translate(-50%, 0);*/
  background: #2d3842;
  border-radius: 8px;
  padding: 12px 16px;
  /*position: relative;*/
}

/*.tooltip::before {*/
/*  content: '';*/
/*  position: absolute;*/
/*  bottom: -5px;*/
/*  left: 50%;*/
/*  width: 20px;*/
/*  height: 20px;*/
/*  display: block;*/
/*  transform: translateX(-50%) rotate(45deg);*/
/*  transform-origin: center;*/
/*  background: #2d3842;*/
/*  border-radius: 4px;*/
/*  z-index: -1;*/
/*}*/

.tooltipDate {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
}

.tooltipItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
}

.tooltipItem + .tooltipItem {
  margin-top: 16px;
}

.tooltipItemName {
  --color: '';

  display: inline-flex;
  align-items: center;
  margin-right: 20px;
}

.tooltipItemName::before {
  content: '';
  display: block;
  width: 12px;
  height: 4px;
  background: var(--color);
  border-radius: 100px;
  margin-right: 8px;
}

.tooltipItemValue {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  border-radius: 8px;
  padding: 24px;
}

.spinner {
  margin: auto;
}

.failed {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--theme-text-primary);
  margin: auto;
}
