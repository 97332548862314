.button {
  border: 1px solid rgba(var(--rgb-lochinvar), 0.32);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 24px;
  font-family: inherit;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: var(--theme-color-primary);
  background: var(--theme-background-secondary);
  position: relative;
}

.button:hover,
.button:focus {
  border-color: var(--theme-color-primary);
}

.error.error {
  color: var(--color-error);
  border-color: var(--color-error);
}

.spinnerWrap {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 8px;
}

.spinner {
  margin: auto;
  color: var(--theme-color-primary);
}
