.rank {
  display: flex;
  align-items: center;
}

.iconWrap {
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
