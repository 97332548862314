.wrap {
  background: var(--theme-background-secondary);
  border-radius: 8px;
  padding: 24px 8px;
  position: relative;
}

.header {
  margin-bottom: 24px;
  padding: 0 16px;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table .left {
  text-align: left;
}

.table .center {
  text-align: center;
}

.table .right {
  text-align: right;
}

.table th {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--theme-table-th-color);
  text-align: left;
  padding: 16px;
}

.table td {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--theme-table-td-color);
  padding: 14px 16px;
}

.table td:first-child {
  border-radius: 8px 0 0 8px;
}

.table td:last-child {
  border-radius: 0 8px 8px 0;
}

.table tbody tr:nth-child(odd) {
  background: var(--theme-table-row-bg);
}

.pagination {
  margin-top: 24px;
}

.spinnerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 8px;
}
