.modal {
  max-width: 894px;
  width: 100%;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--theme-text-primary);
  margin: 0;
}

.subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--theme-text-secondary);
  margin: 8px 0 0;
}

.description {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: var(--theme-text-primary);
  margin-top: 32px;
}
