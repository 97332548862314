.wrap {
  display: inline-flex;
  align-items: center;
  justify-content: inherit;
}

.buttonsWrap {
  flex-shrink: 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  vertical-align: middle;
  color: var(--theme-text-inactive);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 12px;
}

.icon {
  pointer-events: none;
}

.button:hover {
  color: var(--theme-color-primary);
}

.button.active {
  color: var(--theme-color-primary);
}
